<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="群组名称">
            <el-input
              class="mr-10"
              placeholder="请输入群组名称"
              v-model="state.queryForm.name"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="状态">
            <el-select
              v-model="state.queryForm.status"
              class="m-2"
              @change="handleChangeSelect"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in state.typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="handleAddGroup">
              添加群组
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="state.list">
      <el-table-column
        label="序号"
        prop="batchNo"
        show-overflow-tooltip
        align="center"
      />
      <!--  票类型 展会EXHIBITION 论坛FORUM -->
      <el-table-column
        label="投放群组名称"
        prop="name"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="投放数量"
        prop="putCount"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="投放成功"
        prop="putSuccessCount"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div>{{ row.putSuccessCount }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="投放失败"
        prop="putFailCount"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="备注"
        prop="remark"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div>
            {{ row.remark ? row.remark : '暂无备注' }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="状态"
        prop="status"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div>
            {{ filterStatus[row.status] ? filterStatus[row.status] : '无状态' }}
          </div>
        </template>
      </el-table-column> -->

      <el-table-column
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div>{{ filterTime(row.createTime) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="300">
        <template #default="{ row }">
          <el-button type="primary" @click="handleThrow(row)">
            导入并投放
          </el-button>
          <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button type="primary" @click="handleQueryDetail(row)">
            查看详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      v-if="state.total > 10"
      :layout="state.layout"
      :total="state.total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-sizes="state.pageSizes"
      v-model:page-size="state.queryForm.pageSize"
      v-model:currentPage="state.queryForm.pageNum"
    />
    <editTicketsThrow
      :title="editGroupTitle"
      ref="refEditTicketsThrow"
      @noticeRefresh="getData"
    />
    <!-- 导入并投放 -->
    <importThrow ref="refImportThrow"></importThrow>
    <ticketsThrowDialog
      ref="refTicketsThrowDialog"
      @noticeThrowResultRefresh="noticeThrowResultRefresh"
    ></ticketsThrowDialog>
  </div>
</template>
<script>
  export default { name: 'ticketsThrow' }
</script>
<script setup>
  import {
    getCurrentInstance,
    reactive,
    ref,
    onActivated,
    onUnmounted,
    onMounted,
    computed,
  } from 'vue'
  import { useRouter, useRoute } from 'vue-router'

  import { Search, Eleme } from '@element-plus/icons'
  import { checkTricksThrowList } from '@/api/exhibitionManage'
  import { useComponent } from './components/index'
  import { parseTime } from '@/utils/index'
  import Bus from '@/utils/bus'
  import { debounce } from '@/utils/debounce'
  const router = useRouter() // 获取路由实例
  const route = useRoute() // 获取路由对象
  const loading = ref(false) // 列表动画加载
  const { editTicketsThrow, importThrow, ticketsThrowDialog } = useComponent() // 引入组件
  const editGroupTitle = ref('添加群组') // 编辑还是添加的弹框标题
  const { proxy } = getCurrentInstance()
  const refEditTicketsThrow = ref(null) // 编辑还是添加的弹框引用
  const refImportThrow = ref(null) // 导入并投放
  const refTicketsThrowDialog = ref(null) // 导入并投放
  const refThrowResult = ref(null) // 投放结果
  // const filterStatus = {
  //   PENDING_PUT: '待投放',
  //   PUTTING: '投放中',
  //   PART_SUCCESS: '已投放(部分成功)',
  //   SUCCESS: '已投放',
  //   FAIL: '投放失败',
  // }
  const state = reactive({
    pageSizes: [10, 20, 30],
    queryForm: {
      pageNum: 1,
      pageSize: 10,
      name: '',
      ticketCode: '',
      status: '',
    },
    total: 0,
    list: [],
    layout: 'total, sizes, prev, pager, next, jumper',
    editRef: null,
    // typeOptions: [
    //   {
    //     label: '全部',
    //     value: '',
    //   },
    //   {
    //     label: '待投放',
    //     value: 'PENDING_PUT',
    //   },
    //   {
    //     label: '投放中',
    //     value: 'PUTTING',
    //   },
    //   {
    //     label: '已投放(部分成功)',
    //     value: 'PART_SUCCESS',
    //   },
    //   {
    //     label: '已投放',
    //     value: 'SUCCESS',
    //   },
    //   {
    //     label: '投放失败',
    //     value: 'FAIL',
    //   },
    // ],
  })
  // 筛选时间
  const filterTime = (val) => {
    return parseTime(val)
  }
  // 添加群组
  const handleAddGroup = debounce(() => {
    editGroupTitle.value = '添加群组'
    let obj = {
      name: '',
      remark: '',
      batchNo: '',
      delFlag: false,
      ticketCode: state.queryForm.ticketCode,
      smsScene: '',
    }
    console.log('obj', obj)
    refEditTicketsThrow.value.handleOpen(obj)
  })
  // 投放
  const handleThrow = debounce((row) => {
    refImportThrow.value.handleOpen({ ...row })
  })
  // 投放结果
  const noticeThrowResultRefresh = debounce(() => {
    getData()
  })
  // 编辑
  const handleEdit = debounce((row) => {
    editGroupTitle.value = '编辑'
    let params = {
      name: row.name,
      remark: row.remark,
      batchNo: row.batchNo,
      delFlag: false,
      ticketCode: state.queryForm.ticketCode,
      smsScene: row.smsScene,
    }
    refEditTicketsThrow.value.handleOpen({ ...params })
  })
  // 初始化数据
  const getData = async () => {
    loading.value = true
    const { data, total } = await checkTricksThrowList(state.queryForm)
    state.list = data.data
    state.total = data.total
    setTimeout(() => {
      loading.value = false
    }, 500)
  }
  // 下拉事件
  // const handleChangeSelect = (e) => {
  //   state.queryForm.status = e
  // }
  // 查询
  const handleQueryData = debounce(() => {
    state.queryForm.pageNum = 1
    getData()
  })
  // 条数
  const handleSizeChange = debounce((pageSize) => {
    state.queryForm.pageSize = pageSize
    getData()
  })
  // 页码
  const handleCurrentChange = debounce((pageNum) => {
    state.queryForm.pageNum = pageNum
    getData()
  })
  // 跳转详情详情
  const handleQueryDetail = (row) => {
    router.push({
      path: '/exhibitionManage/ticketsThrowDetail',
      query: {
        batchNo: row.batchNo,
      },
    })
  }
  onMounted(() => {
    Bus.$on('noticeTicketsThrowDialog', (res) => {
      refTicketsThrowDialog.value.handleOpen(res)
    })
  })
  onUnmounted(() => {
    Bus.$off(() => {})
  })
  // 激活组件
  onActivated(() => {
    state.queryForm.ticketCode = route.query?.ticketCode || ''
    state.queryForm.ticketCode && getData()
  })
</script>
